import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import rehypeReact from "rehype-react";
import DatePicker from "react-date-picker";

import { Button } from "gatsby-theme-material-ui";

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";

import ButtonGroup from "@material-ui/core/ButtonGroup";

import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import LooksOneIcon from "@material-ui/icons/LooksOne";
import LooksTwoIcon from "@material-ui/icons/LooksTwo";

import Layout from "../components/Layout";
import Container from "../components/Container";
import Payment from "../components/Payment";

import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

const renderAst = new rehypeReact({
  createElement: React.createElement,
}).Compiler;

const dialogStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  tabbed: {
    width: "100%",
    flexGrow: 1,
    color: "#006837",
    background: "white",
    padding: "0 1.25rem",
    paddingTop: "0.3rem",
    marginBottom: theme.spacing(3),
    "& .MuiButtonBase-root": {
      padding: "0.5rem 1rem",
      minWidth: "unset",
      "& .MuiTab-wrapper": {
        fontWeight: "400",
        lineHeight: "1.5",
        textTransform: "none",
        fontSize: "1rem",
        fontFamily: "Nunito",
      },
    },
    "& .Mui-selected": {
      color: "#006837",
    },
    "& .MuiTabs-indicator": {
      height: "5px",
      backgroundColor: `#F45A28`,
    },
  },
  alert: {
    marginTop: "1em",
  },
  dateField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  root: {
    marginTop: "2em",
    maxWidth: 654,
  },
  overline: {
    textAlign: "justify",
    marginTop: "1em",
    fontWeight: "bold",
    marginBottom: "1em",
  },
  inputs: {
    justifyContent: "center",
    alignItems: "center",
  },
  picker: {
    display: "flex",
    justifyContent: "center",
    fontWeight: "400",
    margin: "1em",
  },
  returning: {
    marginBottom: "1.5em",
    fontWeight: "600",
  },
  guestInput: {
    marginBottom: "1em",
    maxWidth: "80px",
    fontWeight: "400",
  },
  guestcount: {
    marginBottom: "1em",
    fontWeight: "600",
    color: "#F45A28",
  },
  choose: {
    marginTop: "1em",
    fontWeight: "600",
  },
  rundown: {
    fontWeight: "400",
    marginBottom: "1.5em",
  },
  pricing: {
    fontWeight: "600",
    color: "#F45A28",
  },
  what: {
    marginTop: "1em",
    marginBottom: "1em",
    fontWeight: "600",
    color: "#F45A28",
  },
  heading: {
    fontVariant: "small-caps",
    marginBottom: "1em",
    fontWeight: "900",
  },
  action: {
    display: "flex",
    justifyContent: "center",
    whiteSpace: "nowrap",
    maxWidth: "220px",
    minWidth: "220px",
    paddingBottom: "5em",
  },
  rightGrid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  headText: {
    position: "relative",
    textAlign: "center",
    paddingTop: "25px",
    height: "200px",
  },
  headImage: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  blurb: {
    maxWidth: 654,
    marginTop: 30,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  tourDescription: {
    textAlign: "justify",
    position: "relative",
    padding: 20,
    zIndex: 1,
    "&::before": {
      position: "absolute",
      width: 200,
      height: 200,
      background: "rgba(3, 99, 55, 0.1)",
      borderRadius: "50%",
      content: '""',
      top: 0,
      left: 0,
      zIndex: -1,
    },
    "&::after": {
      // Half-Circle
      content: '""',
      position: "absolute",
      width: 100,
      height: 50,
      backgroundColor: "rgba(232, 87, 40, 0.1)",
      borderRadius: "100px 100px 0 0",
      bottom: 0,
      left: 0,
      zIndex: -1,
    },
    "& span": {
      // Triangle
      content: '""',
      position: "absolute",
      width: 0,
      height: 0,
      borderLeft: "20px solid transparent",
      borderRight: "20px solid transparent",
      borderBottom: "20px solid rgba(42, 150, 234, 0.1)",
      top: 0,
      right: 0,
      zIndex: -1,
    },
  },
  imageContainer: {
  },
  image: {
  },
  imageBackground: {
  },
}));

const DialogTitle = withStyles(dialogStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose
        ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        )
        : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const now = new Date();
const tomorrow = new Date();
tomorrow.setDate(now.getDate() + 1);

const calcReturn = (l, d) => {
  var returning = new Date(l);
  returning.setDate(l.getDate() + d);
  return returning.toDateString();
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

const PrivateTripsPage = ({
  data: {
    allMarkdownRemark: { edges: content },
  },
}) => {
  const styles = useStyles();
  const [leaving, setLeaving] = React.useState(tomorrow);
  const [cart, setCart] = React.useState(null);
  const [guestCount, setGuestCount] = React.useState(2);
  const [tabIndex, setTabIndex] = React.useState(0);

  const groupedContent = {};
  content.forEach(({ node: product }) => {
    if (!(product.frontmatter.idx in groupedContent)) {
      groupedContent[product.frontmatter.idx] = [];
    }
    groupedContent[product.frontmatter.idx].push(product);
  });
  // console.log(JSON.stringify(groupedContent, null, 4));

  const ProductMulti = ({ products }) => {
    const selector = products.length === 2
      ? (
        <AppBar className={styles.tabbed} position="static">
          <Tabs
            value={tabIndex}
            variant="fullWidth"
            onChange={() => setTabIndex(tabIndex === 0 ? 1 : 0)}
            aria-label="icon tabs for trip duration choice"
            scrollButtons="off"
          >
            <Tab
              icon={<LooksOneIcon />}
              label={products[0]?.frontmatter?.overline}
              {...a11yProps(0)}
              value={0}
            />
            <Tab
              icon={<LooksTwoIcon />}
              label={products[1]?.frontmatter?.overline}
              {...a11yProps(1)}
              value={1}
            />
          </Tabs>
        </AppBar>
      )
      : null;

    return products
      .sort((a, b) => a.tripDuration - b.tripDuration)
      .map((c, i) =>
        products.length === 1 || tabIndex === i
          ? <ProductSingle index={i} selector={selector} {...c} />
          : null
      );
  };

  const ProductSingle = ({
    frontmatter: c,
    htmlAst,
    selector,
    isVisible,
    index,
  }) => {
    const image = getImage(c.image);
    return (
      <Box
        id={`guest-count-${c.title}-${index}`}
        component="div"
        visibility={isVisible}
      >
        {/* xs, sm, md, lg, and xl. */}
        <Container>
          <Typography
            className={styles.heading}
            variant="h4"
            align="center"
            component="h4"
            color="primary"
          >
            {c.title}
          </Typography>
        </Container>
        <Container maxWidth="md" className={styles.imageContainer}>
          <div className={styles.imageBackground}></div>
          <GatsbyImage className={styles.image} alt={c.title} image={image} />
        </Container>
        <Typography
          className={styles.overline}
          variant="body1"
          align="justify"
          color="initial"
        >
          {selector ?? c.overline}
        </Typography>
        <Container>
          <Typography
            className={styles.what}
            variant="h5"
            align="center"
            component="h5"
          >
            {"What's Included?"}
          </Typography>
        </Container>
        <Typography
          className={styles.tourDescription}
          variant="body1"
          align="justify"
          color="initial"
        >
          <span></span> {/* For the triangle */}
          {renderAst(htmlAst)}
        </Typography>
        <Grid container className={styles.inputs} spacing={1}>
          <Container>
            <Typography
              className={styles.pricing}
              variant="h6"
              align="center"
              component="h6"
            >
              {c.priceText}
            </Typography>
            <Typography
              className={styles.pricing}
              variant="caption"
              align="center"
              component="h6"
            >
              ({c.minimumGuests} minimum guests)
            </Typography>
          </Container>
          <Grid xs={12} className={styles.choose}>
            <Typography
              variant="h5"
              align="center"
              color="primary"
              component="h5"
            >
              {"When would you like to leave?"}
            </Typography>
          </Grid>
          <Grid xs={12} className={styles.picker}>
            <DatePicker
              minDate={new Date()}
              onChange={setLeaving}
              value={typeof leaving === "string" ? new Date(leaving) : leaving}
            />
          </Grid>
          {leaving
            ? (
              <Grid xs={12} className={styles.returning}>
                <Typography
                  variant="body1"
                  align="center"
                  color="initial"
                  component="div"
                >
                  Returning {calcReturn(leaving, c.tripDuration)}
                </Typography>
              </Grid>
            )
            : null}
        </Grid>
        <Grid align="center" xs={12} className={styles.guestcount}>
          <Grid xs={12} className={styles.guestcount}>
            <Typography
              variant="h5"
              align="center"
              color="primary"
              component="h5"
            >
              {"How many Guests?"}
            </Typography>
          </Grid>
          <ButtonGroup disableElevation variant="text">
            <IconButton
              color="primary"
              aria-label="remove from cart"
              onClick={() => {
                setGuestCount(
                  guestCount > 2 ? guestCount - 1 : 1,
                );
              }}
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
            <Typography
              variant="h3"
              align="center"
              color="primary"
              component="h5"
            >
              {guestCount}
            </Typography>
            <IconButton
              color="primary"
              aria-label="add to cart"
              onClick={() => {
                setGuestCount(guestCount + 1);
              }}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </ButtonGroup>
        </Grid>
        <Container className={styles.action}>
          <Button
            disabled={leaving && !(guestCount >= c.minimumGuests)}
            size="large"
            color="primary"
            variant="contained"
            onClick={() => setCart(c)}
          >
            {guestCount < c.minimumGuests
              ? `Minimum ${c.minimumGuests} guests`
              : c.buttonText}
          </Button>
        </Container>
      </Box>
    );
  };

  const CartDialog = () =>
    cart
      ? (
        <Dialog
          onClose={() => setCart(null)}
          aria-labelledby="customized-dialog-title"
          open={cart}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={() => setCart(null)}
          >
            {cart.title}
          </DialogTitle>
          <DialogContent dividers>
            <Typography
              gutterBottom
              className={styles.pricing}
              variant="caption"
              align="center"
              component="h6"
            >
              {cart.priceText}
            </Typography>
            <Typography
              gutterBottom
              color="primary"
              className={styles.rundown}
            >
              {guestCount} guests leaving on {leaving.toDateString()}{" "}
              and returning {calcReturn(leaving, cart.tripDuration)}
            </Typography>
            <Payment
              metadata={{
                booking: cart.title,
                confirmation: cart.priceText,
                guestCount: guestCount,
                leaving: leaving.toDateString(),
                returning: calcReturn(leaving, cart.tripDuration),
              }}
              itemId={cart.stripe[process.env.NODE_ENV]}
              itemQuantity={cart.flatrate ? 1 : guestCount}
            />
          </DialogContent>
        </Dialog>
      )
      : null;

  return (
    <Layout pageName="privatetrips">
      <Helmet>
        <title>Private Trips from San Diego</title>
      </Helmet>
      <CartDialog />

      <Container maxWidth="md">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          rowSpacing={1}
        >
          <Grid className={styles.blurb}>
            <Typography
              variant="body1"
              align="justify"
              component="div"
            >
              We here at Valle Guadalupe Baja are excited to help guide you on
              the best way to enjoy Mexico's Guadalupe Valley and Southern
              California's Vineyards.
            </Typography>
          </Grid>
          <Grid className={styles.alert}>
            <MuiAlert variant="outlined" severity="info">
              Tours are 100% refundable up to 30 days before your tour start
              date.
            </MuiAlert>
            <hr />
          </Grid>
          <Grid
            container
            maxWidth="md"
            className={styles.root}
            spacing={4}
          >
            {Object.values(groupedContent).map((c) => (
              <ProductMulti products={c} />
            ))}
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default PrivateTripsPage;

export const PrivateTripsPageQuery = graphql`
    query PrivateTripsPageQuery {
        allMarkdownRemark(
            filter: { frontmatter: { type: { eq: "privatetrips" } } }
            sort: { fields: frontmatter___idx, order: ASC }
        ) {
            edges {
                node {
                    htmlAst
                    frontmatter {
                        idx
                        flatrate
                        title
                        overline
                        url
                        buttonText
                        minimumGuests
                        priceText
                        tripDuration
                        stripe {
                            development
                            production
                        }
                        image {
                            childImageSharp {
                                gatsbyImageData(
                                    placeholder: BLURRED
                                    formats: [AUTO, WEBP, AVIF]
                                )
                            }
                        }
                    }
                }
            }
        }
    }
`;
