import React, { useEffect, useState } from "react";
import {
    PaymentElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";

import addToMailchimp from "gatsby-plugin-mailchimp";
import Container from "../components/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button } from "gatsby-theme-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    action: {
        display: "flex",
        justifyContent: "center",
        whiteSpace: "nowrap",
        maxWidth: "220px",
        minWidth: "220px",
    },
    error: {
        marginTop: "1.5em",
        color: "brown",
        align: "center",
    },
    submit: {
        marginTop: "1.5em",
        align: "center",
    },
    field: {
        padding: "12px",
        borderRadius: "5px",
        transition:
            "background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease",
        border: "1px solid #e6e6e6",
        boxShadow:
            "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)",
    },
    checkbox: {
        padding: "0.25em",
    },
    input: {
        marginBottom: "1em",
        fontSize: "14.88px",
        fontWeight: 400,
        transition:
            "transform 0.5s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1)",
    },
    label: {
        lineHeight: 1.15,
        boxSizing: "border-box",
        fontSize: "14.88px",
        fontWeight: 400,
        transition:
            "transform 0.5s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1)",
    },
}));

export default function CheckoutForm() {
    const styles = useStyles();
    const stripe = useStripe();
    const elements = useElements();
    const [email, setEmail] = useState("");
    const [emailConsent, setEmailConsent] = useState(true);
    const [name, setName] = useState("");
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage(
                        "Your payment was not successful, please try again."
                    );
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        if (emailConsent) await addToMailchimp(email);
        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: "https://www.valleguadalupebaja.com/thank-you-pt",
                receipt_email: email,
                payment_method_data: {
                    billing_details: {
                        name: name,
                        email: email,
                    },
                },
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occurred.");
        }

        setIsLoading(false);
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <label className={styles.label} for="payment_name">
                Cardholder Name
                <div className={styles.input}>
                    <input
                        id="payment_name"
                        type="text"
                        aria-invalid="false"
                        aria-required="true"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className={styles.field}
                        placeholder="Aimee Cruz"
                    />
                </div>
            </label>
            <br />
            <label className={styles.label} for="payment_email">
                Email
                <div className={styles.input}>
                    <input
                        id="payment_email"
                        type="email"
                        aria-invalid="false"
                        aria-required="true"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className={styles.field}
                        placeholder="aimee@email.com"
                    />
                </div>
            </label>
            <PaymentElement id="payment-element" />
            <br />
            <Typography color="primary" variant="caption">
                <FormGroup row>
                    <FormControlLabel
                        className={styles.checkbox}
                        control={
                            <Checkbox
                                checked={emailConsent}
                                color="primary"
                                onChange={() => setEmailConsent(!emailConsent)}
                                name="email-consent"
                            />
                        }
                        label="Sign me up to receive updates, deals and other exclusive Valle Guadalupe Baja promotions."
                    />
                </FormGroup>
            </Typography>
            <Container className={styles.action}>
                <Button
                    type="submit"
                    className={styles.submit}
                    size="large"
                    color="primary"
                    variant="contained"
                    disabled={
                        isLoading || !email || !name || !stripe || !elements
                    }
                    id="submit"
                >
                    <span id="button-text">
                        {isLoading ? <CircularProgress /> : "Pay now"}
                    </span>
                </Button>
                {/* Show any error or success messages */}
            </Container>
            {message && (
                <div className={styles.error} id="payment-message">
                    {message}
                </div>
            )}
        </form>
    );
}
