import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Elements } from "@stripe/react-stripe-js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";

// import "./Payment.css";

const stripePromise = loadStripe(process.env.STRIPE_KEY);

const useStyles = makeStyles((theme) => ({
    pricing: {
        fontWeight: "600",
        color: "#F45A28",
    },
}));

const Payment = (props) => {
    const styles = useStyles();
    const [clientSecret, setClientSecret] = React.useState();
    const [price, setPrice] = React.useState();

    React.useEffect(() => {
        (async () => {
            const response = await fetch(process.env.STRIPE_WS, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    metadata: props.metadata,
                    items: [
                        {
                            id: props.itemId,
                            quantity: props.itemQuantity,
                        },
                    ],
                }),
            });
            const { clientSecret, price } = await response.json();
            const dollars = new Intl.NumberFormat(`en-US`, {
                currency: `USD`,
                style: "currency",
            }).format(price / 100);
            setPrice(dollars);
            setClientSecret(clientSecret);
        })();
    }, [props.itemId, props.itemQuantity, props.metadata]);

    const appearance = {
        theme: "stripe",
        variables: {
            colorPrimary: "#066837",
        },
    };
    const options = {
        clientSecret,
        appearance,
    };

    return (
        <div id={`stripe-${props.itemId}-${props.itemQuantity}`}>
            {clientSecret && price ? (
                <>
                    <Typography
                        gutterBottom
                        className={styles.pricing}
                        variant="caption"
                        align="center"
                        component="h6"
                    >
                        Total: {price} USD
                    </Typography>
                    <Elements options={options} stripe={stripePromise}>
                        <CheckoutForm />
                    </Elements>
                </>
            ) : (
                <CircularProgress />
            )}
        </div>
    );
};
export default Payment;
